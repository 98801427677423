export const uz_messages = {
  yes: 'Ha',
  no: "Yo'q",
  n: "№",
  create: "Yaratish",
  age: "Yosh",
  limit: "Ishtirokchilar soni",
  new_m: "Yangi {m}",
  edit_m: "O'zgartirish  {m}",
  edit: "O'zgartirish",
  main: 'Bosh sahifa',
  search: 'Topish',
  gender: "Jins",
  position_2: "Lavozim (2-chi)",
  department: "Bo'limlar",
  department_: "Bo'lim",
  department_y: "Bo'limlar",
  settings: "Sozlamalar",
  datas: "Дата",
  full_name: "Ism va familiya",
  created_at: "Yaratilgan sana",
  updated_at: "O'zgartirish sanasi",
  name: "Ism",
  nameGiven: "Nomi",
  nameFull: "Ism",
  requirements: "Talab",
  requirements_y: "Talablar",
  reason: "Sabab",
  reasontype: "Sabab turi",
  staffAssentReason:"So'rayapti",
  reason_y: "Sabablari",
  priority: " Ustuvorlik",
  priority_y: " Ustuvorlik",
  position: "Lavozim",
  position_p: "Lavozimlar",
  graphicType: "Grafik turi",
  graphicType_p: "Grafiklar tur",
  company: "Kompaniya",
  company_y: "Kompaniyalar",
  language: "Tillarni bilishi",
  language2: "Tillarni bilishi",
  language_y: "Tillarni bilishi",
  currency: "Valyuta",
  currency_y: "Valyuta",
  salary_currency: "Valyuta O/k",
  salary_currency_y: "Valyuta O/k",
  branch: "Filiallar",
  branch_a: "Filial",
  branch_parental: "Bosh filial",
  skill: "Ko'nikmalar",
  skill_y: "Ko'nikmalar",
  total_working_day: "Ish haftasi",
  graphic: "Ish grafigi",
  language_graphic_type: "Iltimos, ism kiriting",
  employmentType: "Bandlik turi",
  province: "Shahar",
  region: "Shahar",
  region_: "Mintaqa",
  vacancy: "Ishlar",
  last_name: "Familiya",
  middle_name: "otasini ismi",
  date_of_birth: "Tug'ilgan kun",
  phone_number: "Telefon",
  email: "Elektron pochta manzili",
  status: "Holat",
  role: "Rollar",
  security_location: "Zona",
  securityLocations_y: "Zona",
  deviceType: "Qurilma turlari",
  deviceType_y: "Qurilma turlari",
  department_title: "Bo'lim va bo'limlar",
  parent_department_id: "Bosh bo'lim",
  min_wage_y: "Min O/K",
  max_wage_y: "Maks O/K",
  phone_number_y: "Tel raqami",
  summary: "Xulosa",
  UploadFile: "Faylni yuklash",
  UploadFileText: " jpg/png fayllari 500 KB dan kichik ",
  serial_number: "Ishlab chiqarish raqami",
  ip_address: "IP manzili",
  device: "Qurilmalar",
  device_y: "Qurilma",
  income: "Daromad darajasi",
  work_type: "Xodim holati",
  staff_work_type: "Ish turi",
  district_id: "Hudud ",
  district: "Hudud",
  nation: "Millat",
  nation_y: "Millat",
  code: "Kod",
  okurs: "Teskari kurs",
  profile: "Profil",
  dateAdoption: "Qabul qilish sanasi",
  staffTest: "Sinov ",
  operating: "joriy",
  dismissed: "Rad etilgan",
  save: "Saqlash",
  close: "Yopish",
  candidates: "Nomzodlar",
  find: "Qidirmoq",
  delete: "o'chirish",
  daily: "Kundalik",
  from: "Kimdan",
  before: "Oldin",
  dinner: "Kechki ovqat",
  users: "Foydalanuvchilar",
  user: "Foydalanuvchi",
  password: "Parol",
  confirm_password: "Parolni qaytadan kiriting",
  finance: "Moliya",
  client: "Mijoz",
  access_rights: "Kirish huquqlari",
  active: "Faol",
  inactive: "Faol emas",
  working_hours_report: "Ish vaqti haqida hisobot",
  age: "Yosh",
  nv_monitoring: "HV monitoringi",
  new_incoming_payment: "Yangi kiruvchi to'lov",
  incoming: "Kiruvchi",
  outgoing: "Chiquvchi",
  employees: "Xodimlar",
  payment_type: "To'lov turi",
  amount: "so'm",
  check: "Schet",
  check_a: "Schetlar",
  salary_type: "Ish haqi turi",
  salary: "Ish haqi",
  bank: "Bank",
  checking_account: "Hisob raqami",
  expiration_date: "tugash muddati",
  comment: "Izoh",
  overtime: "Vaqt o'tgan sari",
  total: "Jami:",
  being_late: "kechikish",
  other_penalties: "Boshqa jarimalar",
  penalties: "Jarimalar",
  on_hands: "Qo'lda",
  passport_number: "Pasport seriya raqami",
  passport_issued_by: "Kim tomonidan berilgan pasport",
  passport_valid_until: "Pasportning amal qilish muddati",
  inn: "INN",
  telegram:"Telegram",
  telegram_staff:"Telegram Staff",
  job: "Ish",
  hiring_date: "ishga qabul qilish sanasi",
  manager: "Nazoratchi",
  allow_th_employee_to_access_the_system: "Xodimga tizimga kirishga ruxsat bering.",
  allow_salary: "Ish haqi.",
  add_candidates: "Nomzodlarni qo'shing",
  description: "Tavsif",
  photo: "Fotosurat",
  min: "mini",
  max: "maks",
  recruiting: "ishga qabul qilish",
  desktop: "Ish stoli",
  indicators: "Ko'rsatkichlar",
  journal: "Jurnal",
  quit: "Chiqish",
  branch_name: "Filial nomi",
  mfo: "MFО",
  number: "Raqam",
  account: "Schet",
  card_number: "Karta raqami",
  penalty_name: "Jarima nomi",
  bonus_name: "Bonus nomi",
  payment_date: "to'lov sanasi",
  adaptation: "Moslashuv",
  agreement_employees: "Shartnomalar (XODIMLAR)",
  login: "Kirish",
  permission: "Ruxsatlar",
  synchronization: "Sinxronizatsiya",
  tests: "Testlar",
  wage: "Ish haqi",
  dafault: "Standart",
  real_amount: "Haqiqiy miqdor",
  bonus_amount: "Bonus miqdori",
  payment_for: "Uchun to'lov",
  family_location: "Oilaviy ahvoli",
  url: "Havola",
  age: "Yosh",
  wages: "Ish haqi",
  complete_form: "Shaklni to'ldiring",
  KPP: "KPP ",
  guest: "Mehmon",
  card: "Avtomobil",
  copyUrl: "URLni nusxalash",
  vacancyNotAvailable: "Ish mavjud emas",
  fire_staff: "Ishdan bo'shatish",
  user_monitoring: 'Foydalanuvchi monitoringi',
  car_driver: "Asosiy haydovchi",
  new_operation: "Yangi operatsion tizim",
  update_operation: "Operatsion tizimni o'zgartirish",
  transport: "Transport",
  arrival_date_time:"Kelish sanasi va vaqti",
  arrival_date: "Kelish sanasi",
  arrival_time: "Kelish vaqti",
  difference: "Farq",
  managed_task: "Vazifani boshqarish",
  employee_entrance: "Xodimga kirish",
  employee_exit: "Xodimning chiqishi",
  entrance: "Kirish",
  exit: "Chiqish",
  type: "Turi",
  district: "Hudud",
  min_wage: 'Eng kam ish haqi',
  max_wage: 'Maksimal ish haqi',
  min_age: 'Minimal yosh',
  max_age: 'Maksimal yosh',
  description: 'Tavsif',
  symbol: 'Belgi',
  staff: 'Xodimlar',
  active: 'Faol',
  inactive: 'Faol emas',
  role_is_not_set: 'Rol tayinlanmagan ',
  new: 'Yangi',
  amount: 'miqdor',
  amount_a: "So'm",
  comment: 'Izoh',
  attendance_type: "Ishtirok etish turi",
  port: "Port",
  car: "Avtomobil",
  guest_coming_reason: "Kimga kelding?",
  visit_date: "Kelish sanasi",
  visit_date_time: "Kelish sanasi va vaqti",
  visit_time: "kelish vaqti ",
  left_date: "Chiqish vaqti",
  left_date_time: "Chiqish sanasi va vaqti",
  left_time: "Chiqish vaqti",
  car_number: "Avtomobil raqami",
  car_color: "Mashina rangi",
  car_type: "Mashina turi",
  car_model: "Avtomobil modeli",
  additional_information: "Qo'shimcha ma'lumot",
  add_guest: "Mehmon qo'shing",
  add_company: "Kompaniya qo'shish",
  address: "Manzil",
  add_car: "Avtomobil qo'shish",
  add_phone_number: "Avtomobil qo'shish",
  visitor: "Mehmon",
  update_visitor: "Mehmonni tahrirlash",
  total_visit: "Jami tashrif",
  car_information: "Avtomobil haqida ma'lumot",
  supported_formats: "Fayl formati:  doc, docx, pdf, jpg, jpeg, gif, svg, png",
  size_limit_5: "Fayl hajmi: 5 mb",
  candidates: "Nomzodlar",
  attendance: "Davomat",
  time: "Vaqt",
  attendance_type: "Ishtirok etish turi",
  img: "fotosurat",
  verfired_type: "Tasdiqlash turi",
  today: "Bugun",
  refresh: "Yangilash",
  exit_target: "Maqsaddan chiqish",
  expected_emp: "Kirish rejasi",
  chel: "odam",
  factual_emp: "Kirish fakti",
  transport_type: "Avtomobil turi",
  client_transport: "Qarshi tomon mashinasi",
  company_transport: "kompaniya mashinasi",
  product: "Mahsulot",
  product_name: "Mahsulot nomi",
  quantity: "Miqdori",
  measurement_type: "O'lchov turi",
  copy:"Nusxalash",
  add_new: "Yangi qo'shish",
  shipping_letter:"yuk xati",
  user_activity:"Foydalanuvchining aktivligi",
  id:'Identifikator',
  method_type:"Usul turi",
  browser_type:"Brauzer turi",
  social: "Manba",
  where_know:"Qayerdan bilib oldingiz",
  line:"Liniya",
  accept:"Qabul qilmoq",
  education:"Ta'lim",
  header:"Mavzu",
  title:"Matn",
  periot: "Oldindan to'lov sanasi",
  left_time: "Ketish vaqti",
  Tuesday:"seshanba",
  Wednesday:"chorshanba",
  Thursday:"Payshanba",
  Friday:"Juma",
  Saturday:"shanba",
  Sunday:"yakshanba",
  Monday:"dushanba",
  'settings profile': 'Profil sozlamalari',
  'settingCompany': "Kompaniyalar ma'lumoti",
  'logout': 'Chiqish',
  'category': 'Turkum',
  'categories': 'Turkumlar',
  'telegram_managers':'Telegram xabari',
  'telegram_manager':'Telegram xabari',
  vacation: "Ta'til hisobi",
  children:"Bolalar",
  newcomestaffs:"Yaqinda ishga olinganlar",
  completestaffprofile:"Profilni to'ldirilgan foizi",
  staffTests:"Sinov",
  operatings: "Ishlayotgan",
  dismisseds: "Ishdan bo'shagan",
  average_age:"O'rtacha yosh",
  arrivals_and_departures:"Kelish va ketish nisbati",
  accepted_CV:"Qabul qilingan rezyumelar",
  total_qty:"Jami miqdori",
  present:"Borlar",
  missing:"Yo'qlar",
  past_birthdays:"O'tgan tug'ilgan kunlar",
  today_birthdays:"Bugun tug'ilgan kunlar",
  expected_birthdays:"Kutilayotgan tug'ilgan kunlar",
  count_gender:"Kompaniyadagi erkaklar va ayollar soni",
  not_data:"Hozircha maʼlumot yoʻq",
  reasons_for_layoffs:"Ishdan bo'shatish sabablari ",
  official_and_unofficial :"Kompaniyadagi rasmiy va norasmiylar soni",
  faceidregistration:"Face ID ro'yxatdan o'tish",
  registered:"Ro'yxatga olingan",
  not_registered:"ro'yxatdan o'tmagan",
  compleinProfile:"Filiallar bo'yicha to'ldirilgan anketalar, %",
  worksPlace:"Ish tajribasi",
  salary_by_branches:"Filiallar bo'yicha o'rtacha ish haqi",
  districtByStaff:"Hududlar bo'yicha xodimlar soni",
  will_arrive:"Yetib keladi",
  not_come:"Yetib kelmaydi",
  answer_expected:"Javob kutilmoqda",
  assign_role:"Rol tayinlash",
  excel:"Excel",
  weekly:"Hafta",
  monthly:"Oy",
  yearly:"Yil",
  coming:"Kelyapti",
  pending:"Kutish jarayonida",
  additional_rest:"Qo'shimcha ish",
  o_salary: "Ish haqi",
  bonuses: "Bonuslar",
  avance:"Avans",
  tms:"Kirish | Ketish YUK",
  employee_kpp:"Kelish | Ketish Xodimlar",
  operation:"operatsion tizim",
  company_machines:"Kompaniya mashinalari",
  leave_requests:"Ruhsat so'rash",
  report_card:"hisobot kartasi",
  structure:"Tuzilishi",
  additional_work:"qo'shimcha ish",
  complaint:"Shikoyat",
  calendar:"Kalendar",
  fio:"To'liq ismi sharif",
  general_working_day:"Umumiy ish kuni",
  general_working_hour:"Umumiy ish vaqti",
  records_of_the_month :"Oy kuni bo'yicha ishda qatnashish va kelmaslik yozuvlari",
  unrevealed:"Kelmagan",
  waiting:"Kutilmoqda",
  received:"Qabul qildi",
  denied:"Rad etilgan",
  all:"Hammasi",
  those_present:"Hozirda borlar",
  released:"Chiqib ketganlar",
  missing:"Kelmaganlar",
  with_reason:"Sabab bilan",
  without_reason:"Sababsiz",
  online:"Onlayn",
  confirm:"Tasdiqlamoq",
  approved:"Tasdiqlangan",
  rejected:"Rad etilgan",
  date_time:"sana va vaqt",
  performance_type:"ishlash turi",
  qty:"Miqdor",
  details:"Tafsilotlar",
  permis:"ruxsat",
  parental_permission:"BOsh ruxsat",
  translated_resolution:"Almashtirilgan ruxsat",
  children_permission:"Bolalar ruxsati",
  children_translated_permission:"Bolalar tarjimasi ruxsati",
  telegram_password:"Telegram parol",
  last_active:"Oxirgi kirgani",
  subordinates:"Bo'ysunuvchilar",
  document:"Hujjatlar",
  file:"Fayllar",
  additional_information:"Qo'shimcha ma'lumot",
  work_history:"Ish tarixi",
  cancel:"Bekor qilish",
  personal:"Shaxsiy",
  limit_work_time:"Ish me'yori",
  actual_working_time:"Haqiqiy ish vaqti (soat)",
  salary_rotation:"Ish haqi va rotatsiyalar",
  rotation:"Aylanishlar",
  quality_control:"Sifat nazorati",
  process:"Jarayon",
  name_process:"Nomlash jarayoni",
  norms:"Me'yor",
  fact:"Fakt",
  assigned_salary:"Belgilangan ish haqi",
  last_month:"O `tgan oy",
  current_month:"Joriy oy",
  allowed:"Ruxsat berilgan",
  rejected:"Rad etilgan",
  request_time:"So'rov vaqti",
  licenses_and_certificates:"Litsenziyalar va sertifikatlar",
  gave_out:"Berdi",
  issued:"Berilgan",
  release_date:"Ishlab chiqarilish sanasi",
  health_information:"Sog'liqni saqlash ma'lumotlari",
  growth:"Bo'yi",
  weight:"Og'irligi",
  blood_type:"Qon guruhi",
  locations_health_information:"Joylar sog'liqni saqlash ma'lumotlari",
  foreign_languages:"Xorijiy tillar",
  degree:"Степень",
  lang:"Til",
  mobil_number:"Mobil telefon raqami",
  work_phone:"Ish telefoni",
  facebook_url:"Facebook URL manzili:",
  instagram_url:"Instagram URL:",
  twitter_url:"Twitter URL:",
  linkedIn_url:"LinkedIn URL:",
  telegram_username:"Telegram foydalanuvchi nomi",
  tiktok_username:"Tiktokdagi foydalanuvchi nomi",
  educational_institution:"O'quv muassasasi",
  faculty:"Fakultet",
  beginning_date:"Boshlanish sanasi",
  expiration_date:"Tugash muddati",
  skill:"Malaka",
  not_coming_day:"Kelmagan kun",
  hours_or_more:"8 soat yoki undan ko'proq",
  less_than_hours:"8 soatdan kam",
  day_absence:"Dam olish kuni",
  not_come_work_day:"Ishga kelmagan kun",
  complaints:"Shikoyatlar",
  subject_complaint:"Shikoyat mavzusi",
  izn:"Izn",
  date_of_departure:"Ketish sanasi",
  arrival_date:"Kelish sanasi",
  place_work:"Ish joylari",
  place_work_name:"Ish joyining nomi",
  data_from_to:"Sana dan gacha",
  display_and_wallpaper: "Ish stoli va ekran",
  dynamic_wallpaper: "Dinamik fon rasmi",
  notes:"Eslatmalar",
  from_data:"Sanasidan boshlab",
  to_data:"Sanasigacha",
  add_document:"Hujjat qo'shish",
  labor_contract:"Mehnat shartnomasi",
  material_liability:"Moddiy javobgarlik",
  confidentiality_agreement:"Maxfiylik shartnomasi",
  employment_contract:"Mehnat shartnomasini bekor qilish",
  finance_training:"Moliyaviy trening",
  plastic_card:"Plastik karta",
  card_number:"Karta raqami",
  date_of_expiry:"Quyidagi sanagacha foydalanilsin",
  card_organ:"Karta organi",
  amount_per_hour:"Bir soatlik miqdor",
  unofficial_amount:"Norasmiy miqdor",
  official_amount:"Rasmiy miqdor",
  type_salary:"O/K turi",
  amount_money:"Pul miqdori",
  in_hour:"Soatda",
  access_control:"Kirish nazorati",
  id_employee:"Xodimning ID si",
  contact:"Kontakt",
  add_skill:"Malaka qo'shish",
  Level:"Daraja",
  sort_panel: "Boshqaruv paneli",
  country_passport:"Паспорт страны",
  Send_Manager:"Send Manager",
  departure_time:"Время отправления",
  access_time:"Время доступа",
  model:"Модельный",
  izn_password:"Пароль бота",
  sort_panel: "Приборная панель",
  schedule:'Расположение',
  new_version:'Новая версия',
  old_version:'Старая версия',
  causes_operating_system:'Причины операционки',
  late_to_works:'Ishga kech qolganlar',
  coming_date_time:'Kelish kuni va vaqti',
  coming_time:'Kelish vaqti',
  in_count:'Kirishlar soni',
  out_count:'Chiqishlar soni',
  total_toilet_minuts:'Umumiy vaqt',
  info_tms_statistic:'Oxirgi YUK kirish|chiqish lar',
  info_toilet_statistic:'Top-20 ta hojatxonaga tashrif buyuruvchilar',
  director:"Raxbar",
  country:"Mamlakat",
  logo:"Logotip",
  web_site:"Web-sayt",
  application:"Ariza",
  explanatoryLetter:"Tushuntirish xati",
  active_works:"Faol xodim | Obod Xudud",
  active_person:"Faol xodim",
  prosperous_place:"Obod Xudud",
  parent_company_id: "Bosh kompaniya",
  manual:"Руководство создано",
  leader:"Raxbar",
  numeric_currency_code:'Цифровой код валюты',
  letter_currency_code:'Буквенный код валюты',
  payed:"To'langan",
  advance_salary:"Avans",
  pentaly_salary:"Штрафы",
  hour:'h',
  minut:'m',
  dateuvalen: "Bo'shatilgan sana",
  department_full:"Bu bo'limda ishchi o'rni to'lgan yoki nechta ishchi olish kerkaligi ko'rsatilmagan!",
  pinfl:"PINFL",
  dosnt_have_salary:'Ish haqqi kiritilmaganlar',
  have_salary:'Ish haqqi kiritilganlar',
  organizations:'Организация',
  value:"Qiymat",
  percent:"Foiz",
  value_type:"Qiymat turi",
  bonus_penalty_reasons:'Причины санкции | бонусы',
  is_free:"Свободное",
  work_times:'Ishlash vaqtlari',
  online_works:'Online Ishlar',
  vacation_managements:"Управление отпуском",
  vacation_management:"Управление отпуском",
  accept_vacation_managment:"Месяц отпуска",
  not_attached:"Не прикреплен",
  recruiting:'Ishga qabul qilish',
  increase_knowledge:'Bilimini oshirish',
  for_all:'Barchasi uchun',
  view:"Ko'rish",
  applicationType:'Ariza turi',
  seer:'Xabardor xodim',
  whose_side:'Xarajat kim tomonidan',
  show_position:"Ariza shapkasida lavozim ko'rinsin",
  tariff_category:'Тарифный разряд',
  tariff_categories:'Тарифный разряд',
  coefficient:'Разряд',
  minimum_wages:'Минимальная оплата труда',
  minimum_wage:'Минимальная оплата труда'

}
